import { request } from "lib/api";
import { handleErrors } from "lib/handleErrors";
import { ILinesUpdate, IDeviceHestoryFilter } from "types";
import moment from "moment";

// Получение последних данных фотореле
const getPhotoRelayHistory = async (pr_id: string, limit: number) => {
  const params = `limit=${limit}`;
  const resp = await request(
    `/v1/photorelay-history/${pr_id}?${params}`,
    "GET"
  );

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Получение истории модемов
const getModemHistory = async (
  ll_id: string,
  filter: IDeviceHestoryFilter,
  limit?: number,
  offset?: number
) => {
  const from_ts = filter[0]
    ? `&from_ts=${encodeURIComponent(
        moment(filter[0]).startOf("day").format()
      )}`
    : "";
  const to_ts = filter[1]
    ? `&to_ts=${encodeURIComponent(moment(filter[1]).endOf("day").format())}`
    : "";
  const pagination = limit ? `&limit=${limit}&offset=${offset}` : "";
  const params = `${pagination}${from_ts}${to_ts}`;
  const resp = await request(
    `/v1/modem-signal-history/${ll_id}?${params}`,
    "GET"
  );

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Получение истории тестирования модема
const getLineHistory = async (ll_id: string, limit: number) => {
  const params = `?limit=${limit}`;
  const resp = await request(`/v1/expl/${ll_id}/history${params}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Получение последней записи истории тестирования модема
const getLastLineHistory = async (ll_id: string) => {
  const resp = await request(`/v1/expl/${ll_id}/last_history`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Запуск тестирования модема
const startTest = async (ll_id: string) => {
  const resp = await request(`/v2/expl/test/${ll_id}`, "POST");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Обновляет ЛО целиком либо заменяет в ней модем/счётчик
const updateLightingLine = async (ll_id: string, line: ILinesUpdate) => {
  const resp = await request(
    `/v1/lighting-line/${ll_id}`,
    "PUT",
    JSON.stringify(line)
  );

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// создание новой ЛО
const addLightingLine = async (line: ILinesUpdate) => {
  const resp = await request(`/v1/lighting-line`, "POST", JSON.stringify(line));

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// получение ЛО по еe id
const getLightingLine = async (line_id: string) => {
  const resp = await request(`/v1/lighting-line/${line_id}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// удаление ЛО
const deleteLightingLine = async (ll_id: string) => {
  const resp = await request(`/v1/lighting-line/${ll_id}`, "DELETE");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// получение текущего состояния связи
const getCurrentModemState = async (ll_id: string, modem_id: string) => {
  const resp = await request(
    `/v1/modem-state?ll_id=${ll_id}&modem_id=${modem_id}`,
    "GET"
  );

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// получение устройств ЛО по еe id
const getLightingLineDevices = async (line_id: string) => {
  const resp = await request(`/v1/lighting-line-devices/${line_id}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Удаляет связь между ЛО и привязанными к ней устройствами. Выводит ЛО из эксплуатации.
const unlinkLightingLineDevices = async (line_id: string) => {
  const resp = await request(
    `/v1/lighting-line/${line_id}/unlink-devices`,
    "POST"
  );

  if (!resp.ok) {
    handleErrors(resp);
  }

  return line_id;
};

export const devices = {
  getPhotoRelayHistory,
  getModemHistory,
  getLineHistory,
  getLastLineHistory,
  startTest,
  updateLightingLine,
  addLightingLine,
  getLightingLine,
  deleteLightingLine,
  getCurrentModemState,
  getLightingLineDevices,
  unlinkLightingLineDevices,
};
