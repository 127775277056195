import { createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import { intl } from "lib/locale";

// actions
import { editLightingLineNotifyParams } from "feat/asuno/actions";
import { updateLightningLinesState } from "feat/indications/actions";

// types
import {
  ThunkAPI,
  IPhotoRelayHistory,
  IModemHistoryData,
  ILineHistory,
  ILinesUpdate,
  ILines,
  ILinkState,
  IDeviceHestoryFilter,
} from "types";
import moment from "moment";

// Получение истории фотореле
export const getPhotoRelayHistory = createAsyncThunk<
  IPhotoRelayHistory[],
  { pr_id: string; limit: number },
  ThunkAPI
>(
  "objects/getPhotoRelayHistory",
  async ({ pr_id, limit }, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.devices.getPhotoRelayHistory(pr_id, limit);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Получение истории модемов
export const getModemHistory = createAsyncThunk<
  IModemHistoryData,
  { ll_id: string; currentPage: number; filter: IDeviceHestoryFilter },
  ThunkAPI
>(
  "objects/getModemHistory",
  async (
    { ll_id, currentPage, filter },
    { extra: { api }, rejectWithValue, getState }
  ) => {
    const pageSize = getState().pagination;
    const offset = pageSize * (currentPage - 1);
    try {
      const data = await api.devices.getModemHistory(
        ll_id,
        filter,
        pageSize,
        offset
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Получение всей истории модема для графика
export const getAllModemHistory = createAsyncThunk<
  IModemHistoryData,
  { ll_id: string; filter: IDeviceHestoryFilter },
  ThunkAPI
>(
  "objects/getAllModemHistory",
  async ({ ll_id, filter }, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.devices.getModemHistory(ll_id, filter);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Получение истории тестирования модема
export const getLineHistory = createAsyncThunk<
  ILineHistory[],
  string,
  ThunkAPI
>(
  "objects/getLineHistory",
  async (ll_id, { extra: { api }, rejectWithValue }) => {
    const limit = 5;
    try {
      const data = await api.devices.getLineHistory(ll_id, limit);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Получение последнего статуса тестирования модема
export const getLineHistoryLast = createAsyncThunk<
  ILineHistory,
  string,
  ThunkAPI
>(
  "objects/getLineHistoryLast",
  async (ll_id, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.devices.getLastLineHistory(ll_id);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Получение истории линии (при окончании тестирования)
// сделано без прелоадера
export const updateLineHistory = createAsyncThunk<
  ILineHistory[],
  string,
  ThunkAPI
>(
  "objects/updateLineHistory",
  async (ll_id, { extra: { api }, rejectWithValue }) => {
    const limit = 5;
    try {
      const data = await api.devices.getLineHistory(ll_id, limit);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Запуск тестирования
export const startTest = createAsyncThunk<null, string, ThunkAPI>(
  "objects/startTest",
  async (ll_id, { extra: { api }, rejectWithValue }) => {
    try {
      await api.devices.startTest(ll_id);

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// получение ЛО по ее id
export const getLightingLine = createAsyncThunk<ILines, string, ThunkAPI>(
  "objects/getLightingLine",
  async (line_id, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.devices.getLightingLine(line_id);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// удаление ЛО
export const deleteLightingLine = createAsyncThunk<string, string, ThunkAPI>(
  "objects/deleteLightingLine",
  async (line_id, { extra: { api }, rejectWithValue }) => {
    try {
      await api.devices.deleteLightingLine(line_id);

      notification.open({
        message: intl.formatMessage({
          id: "substations.substation-deleted",
        }),
      });

      return line_id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Обновляет ЛО целиком либо заменяет в ней модем/счётчик
export const updateLightingLine = createAsyncThunk<
  ILines[],
  {
    ll_id: string;
    line: ILinesUpdate;
    descriptions?: any;
  },
  ThunkAPI
>(
  "objects/updateLightingLine",
  async (
    { ll_id, line, descriptions },
    { extra: { api }, rejectWithValue, getState, dispatch }
  ) => {
    const lines: ILines[] = getState().lightingLineGroups.currentGroup.lines;

    try {
      await api.devices.updateLightingLine(ll_id, line);
      await dispatch(updateLightningLinesState());

      if (descriptions) {
        await dispatch(
          editLightingLineNotifyParams({ line_id: ll_id, body: descriptions })
        );
      }

      const { config_id, ...modifier } = line;
      const updatedLines = lines.map((line) =>
        line.id === ll_id ? { ...line, ...modifier } : line
      );

      notification.open({
        message: line.name,
        description: intl.formatMessage({
          id: "devices.saved-success-msg",
          defaultMessage: "Saved Successfully",
        }),
      });

      return updatedLines;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// создание новой ЛО
export const addLightingLine = createAsyncThunk<ILines, ILinesUpdate, ThunkAPI>(
  "objects/addLightingLine",
  async (line, { extra: { api }, rejectWithValue, dispatch }) => {
    try {
      const data = await api.devices.addLightingLine(line);
      const newLine = await api.devices.getLightingLine(data.line_id);
      await dispatch(updateLightningLinesState());

      notification.open({
        message: intl.formatMessage({
          id: "devices.add-shuno-msg",
          defaultMessage: "Added new SHUNO",
        }),
        description: line.name,
      });

      return newLine;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// получение текущего состояния связи
export const getCurrentModemState = createAsyncThunk<
  {
    ll_id: string;
    modem_id: string;
    link: ILinkState;
  },
  { ll_id: string; modem_id: string },
  ThunkAPI
>(
  "objects/getCurrentModemState",
  async ({ ll_id, modem_id }, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.devices.getCurrentModemState(ll_id, modem_id);

      const link: ILinkState = data?.map(
        (item: ILinkState & { state: string; signal_strength: number }) => ({
          val: item.state === "connected" ? item.signal_strength : 0,
          ts: parseInt(moment(item.ts).format("x"), 10) * 1000,
          modem: item.modem,
        })
      )[0];

      return {
        ll_id,
        modem_id,
        link,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// получение устройств ЛО по ее id
export const getLightingLineDevices = createAsyncThunk<
  { line_id: string; data: ILines },
  string,
  ThunkAPI
>(
  "objects/getLightingLineDevices",
  async (line_id, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.devices.getLightingLineDevices(line_id);

      return { line_id, data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Удаляет связь между ЛО и привязанными к ней устройствами. Выводит ЛО из эксплуатации.
export const unlinkLightingLineDevices = createAsyncThunk<
  string,
  ILines,
  ThunkAPI
>(
  "objects/unlinkLightingLineDevices",
  async (line, { extra: { api }, rejectWithValue }) => {
    try {
      const lineId = await api.devices.unlinkLightingLineDevices(line.id);

      notification.open({
        message: line.name,
        description: intl.formatMessage({
          id: "devices.line-off-msg",
          defaultMessage: "Lighting line disabled successfully",
        }),
      });

      return lineId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
